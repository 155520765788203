import apiReport from 'public/src/pages/common/apiReport/index'
import { SMessage } from '@shein-aidc/sui-message'
import { GETWAY_ERROT_CODE } from './env'
import { runFlowChallenge } from '../../pages/common/risk_libs/geetest.js'
import axios from 'axios'
import { getLocalStorage, stringifyQueryString, parseQueryString } from '@shein/common-function'
import { getSampleControlConfig } from './index'
import { SIMetric } from '../../pages/common/monitor/index'
import { checkApiAnalysisCollect } from '../../pages/common/monitor/http'
import { triggerPrefetchCsrf } from './prefetchCsrf'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { getUserActionTrackerData } from 'public/src/pages/common/userActionTracker/index.js'
import { OestClientHandle } from '@shein-aidc/basis-oesthandle'

//bff的返回836100的内容结构和node的不匹配，这里做一下兼容，bff是将内容放在了info里
export function fixBffChallengeContent(response) {
  const baseURL = response?.config?.baseURL
  const { data: { code = '', info = {} } = {} } = response || {}

  if( code == '836100' && baseURL?.includes('/bff-api') && info?.attachment ){
    let newInfo = { 
      code, 
      sub_code: info.sub_code,
      attachment: info.attachment,
    }
    response.data = newInfo
  }
  return response
}

const DEFAULT_IGNORE_METHOD = ['GET', 'HEAD', 'OPTIONS']
export async function addXsrfTokenInterceptor (config) {
  if (config?.headers?.common['x-csrf-token']) return config
  if (gbCommonInfo?.SHOULD_RENDER_CSRF_TOKEN_ON_SSR === 'on') return config
  if ((config?.ignoreInterceptor || []).indexOf('csrf') > -1) return config
  const method = config.method || 'GET'
  if ((config?.csrfIgnoreMethods || DEFAULT_IGNORE_METHOD).indexOf(method.toUpperCase()) > -1) return config
  if (typeof window === 'undefined') return config
  if (config?.url === '/api/common/csrf/update') return config
  const promise = triggerPrefetchCsrf()
  if (promise) {
    await promise
    // eslint-disable-next-line require-atomic-updates
    config.headers.common['x-csrf-token'] = gbCommonInfo.csrf_token || ''
  }
  return config
}

function tpmRecord(params, options = {}) {
  if (typeof window === 'undefined') return
  window.TPM?.run({
    marketing: 'ClientAnalytics',
    method: '_defineTrack',
    params: { data: params, options }
  })
}
// ===== 获取人机参数 =====

// 同步获取antiin 参数
function getSyncAntiIn(url) {
  if (typeof window === 'undefined' || !window.AntiIn) return ''
  if (!gbCommonInfo?.SYNC_ANTIIN_URLS.some(path => url.includes(path))) return ''

  const AntiIn = window.AntiIn
  if (AntiIn._last) return AntiIn._last

  AntiIn._calculating = AntiIn.syncGetAllEncrypted(AntiIn.Channel.PC, { collect_crosspage: gbCommonInfo.SYNC_ANTIIN_URLS_MORE.some(path => url.includes(path)), version: gbCommonInfo.RISK_SDK_CONFIG.anti || '' })
  AntiIn._last = AntiIn._calculating
  setTimeout(() => AntiIn._last = undefined, 200)

  return AntiIn._calculating
}

export function SyncCommonHeader(config) {
  const antiIn = getSyncAntiIn(config.url) || ''
  const commonHeaders = {}
  if (antiIn) { commonHeaders['Anti-In'] = antiIn }
  config.headers = Object.assign(commonHeaders, config.headers)
  return config
}

// 异步获取antiin 参数
async function getAntiIn(url) {
  if (typeof window === 'undefined' || !window.AntiIn) return ''
  if (!gbCommonInfo?.ANTIIN_URLS.some(path => url.includes(path))) return ''

  const AntiIn = window.AntiIn
  if (AntiIn._last) return AntiIn._last
  if (AntiIn._calculating) return await AntiIn._calculating

  AntiIn._calculating = AntiIn.getAllEncrypted(AntiIn.Channel.PC, { collect_crosspage: gbCommonInfo.ANTIIN_URLS_MORE.some(path => url.includes(path)), version: gbCommonInfo.RISK_SDK_CONFIG.anti || '' })
    .then(_ => {
      AntiIn._calculating = null
      setTimeout(() => AntiIn._last = undefined, 200)
      return AntiIn._last = _
    })
    .catch(() => '')
  return await AntiIn._calculating
}


//修正cookie，比如货币，避免请求时携带错误的货币
export function cookieFix(response) {
  if (typeof window === 'undefined') return response
  //里面有处理CDN缓存导致cookie currency不一致的情况
  UserInfoManager.get({ key: 'currency' })

  return response
}

export async function commonHeader(config) {
  // if (config.schttp?.skipCommonHeader) return config
  // const timezoneOffset = new Date().getTimezoneOffset() / 60
  // const timezone = `GMT${timezoneOffset > 0 ? '-' : '+'}${Math.abs(timezoneOffset)}`
  const antiIn = await getAntiIn(config.url) || ''
  const commonHeaders = {
    // timezone,
  }
  if (antiIn) { commonHeaders['Anti-In'] = antiIn }
  config.headers = Object.assign(commonHeaders, config.headers)
  // END
  return config
}
// ===== END =====

// ===== signature interceptors =====
let firstUpload = true
export async function apiSignatureRequest(config) {
  if (typeof window === 'undefined' || typeof gbCommonInfo === 'undefined') return config

  if (!config.customdata) config.customdata = {}

  let sigUrl = axios.getUri(config)
  if (sigUrl.startsWith(`http`)) {
    // Other sites do not add signatures
    if (!sigUrl.startsWith(location.origin)) return config
    sigUrl = sigUrl.replace(location.origin, '')
  }

  // NOTE: signature 时延埋点
  let signature = ''
  const startTime = Date.now()

  // eslint-disable-next-line require-atomic-updates
  config.customdata['requestStartTime'] = startTime
  // eslint-disable-next-line require-atomic-updates
  config.customdata['subUrl'] = sigUrl.split('?')?.[0]
  config.customdata['sigUrl'] = sigUrl
  sigUrl = sigUrl.split('?')?.[0] + '?' + sigUrl.split('?')?.[1]?.replace(/'/g, '%27')
  if (!gbCommonInfo?.OPEN_SIGNATURE) return config

  try {
    signature = await window._SHEIN_CALC_SIGNATURE_(sigUrl)
    let key_path = 'signature_compute_time'
    if (firstUpload) {
      firstUpload = false
      key_path = 'signature_compute_time_first'
    }
    tpmRecord({
      data: [{
        key_path,
        values: {
          num: (Date.now() - startTime) * 100
        }
      }]
    }, {
      random: 0.05
    })
  } catch (e) {
    // NOTE: signature 签名计算错误上报
    if (typeof window !== 'undefined' && typeof gbCommonInfo !== 'undefined') {
      window.ErrorJs.sendError('error', `${gbCommonInfo?.REPORT_URL?.SA_REPORT_URL}/unusual`, {
        errorMark: 'signature_compute_error',
        errorInfo: {
          stack: e?.stack,
          message: e?.message,
          sigFileName: gbCommonInfo?.SIG_FILE_NAME,
          sigUrl: sigUrl
        }
      })
    }
  }
  // eslint-disable-next-line require-atomic-updates
  config.headers['x-gw-auth'] = signature
  return config
}

export function apiSignatureResponse(response) {
  const code = Number(response?.data?.code)
  const subCode = Number(response?.data?.sub_code)
  if (code === 836000 && subCode === 5501) {
    // 说明算签秘钥失效, 需要刷新页面获取新的算签文件
    location.reload()
  }
  return response
}
// ===== END =====

// ===== 熔断需要提示 “稍后再试” 的拦截器 =====
let cbTipOn = false
// https://doc.weixin.qq.com/txdoc/excel?docid=e2_AEsAzwZpACscvSMWl7wQg6l5QhgQj&scode=APMA2QcuAAkw2w1FtDAEsAzwZpACs&type=1&tab=BB08J2&c=C2A0A0
export function circuitBreakerTip(response) {
  const circuitBreakerTipApi = [
    /\/api\/user\/wishlist(?!\/((check\/good)|(group\/optional\/goods\/get)|(redpoint\/get)))/
  ]

  // ===== 检查当前请求是否匹配熔断提示 =====
  if (circuitBreakerTipApi.some(reg => reg.test(response.config.url)) && typeof response.data?.code !== 'undefined') {
    if (+response.data.code === -4 || +response.data.code === -2) {
      if (!cbTipOn) {
        cbTipOn = true
        SMessage({
          message: gbCommonInfo.language.SHEIN_KEY_PC_17753,
          type: 'none',
          offset: 186,
          onClose: () => cbTipOn = false
        })
      }

      response.data.code = -1       // 让请求走正常错误处理
      response.data.originCode = -4 // 原状态码，界面可能需要拿原状态码进行二次判断
    }
  }
  return response
}
// ===== END =====


//需要判断登录态退出登录，但不需要跳转页面，发起异步接口来更新登录态
export function asyncCheckLogin(schttp) {
  return function(response){
    const code = response.data?.code || ''
    if ( response.config?.schttp?.asyncCheckLogin && ['00101110', '300206'].includes('' + code) ) {
      schttp({
        method: 'POST',
        url: '/api/user/auth/logout',
      })
    }
    return response
  }
}

// ===== 强制登录 的拦截器 =====
export function forceLogin(response) {
  if (response.config.schttp?.needLogin && response.data) {
    if (+response.data.code === 300206 || response.data.msg === 'need login' || response.data.code == '00101110') {
      try {
        import('@login/utils/auth.js').then(({ removeAuthUserInfo }) => {
          removeAuthUserInfo()
        }).catch((err) => {
          console.log('forceLogin:' + err)
        })
      } catch (error) {
        console.log(error + '')
      }
      location.href = gbCommonInfo.langPath + '/user/auth/logout'
    }
  }
  return response
}
// ===== END =====

// ===== 接口异常上报 的拦截器 =====

//反爬拦截：Gateway-Waf-Reject字段枚举值包含：A001、A002（但不包含A002-1）、A004、A005-Challenge、A005-Block，则为反爬拦截
function isWafIntercepted(headers) {
  const wafHeaderValue = headers?.['gateway-waf-reject'] || ''
  const validPatterns = ['A001', 'A002', 'A004', 'A005-Challenge', 'A005-Block']
  const excludedPatterns = ['A002-1']
  return validPatterns.some(pattern => wafHeaderValue.includes(pattern)) &&
         !excludedPatterns.some(excluded => wafHeaderValue.includes(excluded))
}

function isReportBlocked() {
  if (typeof window === 'undefined' || typeof gbCommonInfo === 'undefined') {
    return true 
  }

  const userAgent = navigator.userAgent
  const { USER_AGENT_BLACK_LIST = [] } = gbCommonInfo

  return isUserAgentDisabled(userAgent, USER_AGENT_BLACK_LIST)
}

function isUserAgentDisabled(userAgent, blackList) {
  return blackList.some(keyword => userAgent.includes(keyword))
}

export const apiLog = {
  success: function (response) {
    // console.log(response)
    if (response?.config?.schttp?.apiLogSkip) return response

    if (response?.config?.customdata?.requestStartTime > 0) {
      const timeDiff = Date.now() - (response?.config?.customdata?.requestStartTime || 0)
      const apiPath = response?.config?.url || 'other'
      // 实时上报客户端RT
      checkApiAnalysisCollect(apiPath, { responseTime: timeDiff })
      tpmRecord({
        resource: response?.config?.customdata?.subUrl || 'other',
        data: [{
          key_path: 'request_time',
          values: {
            num: timeDiff
          }
        }]
      }, {
        random: 0.05
      })
    }

    // bff-api请求上报监控日志
    try{
      if (isWafIntercepted(response?.headers) || isReportBlocked()) return response
      
      const { langPath = '' } = gbCommonInfo
      const metricName = response?.config?.useBffApi ? 'bff_api_res_code_error' : 'api_res_code_error'
      const apiUrl = response?.config.customdata?.subUrl || 'other'
      if(response.data?.code != null && response.data.code != '0' && response.data.code != 836100){
        SIMetric.metricCount({
          metric_name: metricName,
          tags: {
            siteuid: gbCommonInfo?.SiteUID,
            errorCode: response.data.code,
            apiUrl: apiUrl.substring(langPath.length),
            hostname: location.hostname,
            referer: document.referer || ''
          },
          message: response.data?.msg || `${apiUrl}请求非0错误`
        }, {
          immediate: true
        })
      }

      // bff返回的站点和当前请求站点不一致时上报
      const resSiteUid = response?.headers?.['site-uid']?.trim()
      if (!resSiteUid || !gbCommonInfo?.SiteUID) return response
      if (resSiteUid !== gbCommonInfo?.SiteUID) {
        SIMetric.metricCount({
          metric_name: 'bff_site_error',
          tags: {
            siteuid: gbCommonInfo?.SiteUID,
            bffSiteuid: resSiteUid || 'other',
            apiUrl: response.config?.customdata?.subUrl || 'other',
            hostname: location.hostname,
            referer: document.referer || ''
          },
          message: 'bff返回站点与当前请求站点不一致'
        }, {
          immediate: true
        })
      }
    }catch(error){
      console.error(error)
    }

    try {
      apiReport.sendReportForScHttp(response)
      // NOTE: signature validate error
      // wiki.pageId=237404804#API%E7%BD%91%E5%85%B3%E9%94%99%E8%AF%AF%E7%BC%96%E7%A0%81-%E5%85%AC%E7%BD%91%E7%BD%91%E5%85%B3%E9%94%99%E8%AF%AF%E7%BC%96%E7%A0%81%E4%BB%A5%E5%8F%8A%E5%93%8D%E5%BA%94%E6%A0%BC%E5%BC%8F
      // https://wiki.dotfashion.cn/pages/resumedraft.action?draftId=992574414&draftShareId=5218c5a2-a944-4605-a396-e77b1ea0b125&
      switch (true) {
        case Number(response?.data?.code) === 836000:
          tpmRecord({
            resource: response?.config?.customdata?.subUrl || 'other',
            data: [{
              key_path: 'request_error_signature',
              values: {
                status_code: String(GETWAY_ERROT_CODE[Number(response?.data?.sub_code)] || 0),
                info: {
                  'message': response?.config?.customdata?.sigUrl
                }
              }
            }]
          }, {
            immediate: true
          })
          break
        default:
          break
      }

    } catch (e) {
      console.log(e)
    }

    try {
      //反爬埋点-实时引擎
      const code = response?.data?.code
      const origin_risk_id = response?.headers?.['risk-id']
      if (code == 836100 && origin_risk_id) {
        const SaPageInfo = {
          page_name: 'page_risk_crawler_block',
          page_id: 6407
        }
        window?.sa('set', 'setPageData', SaPageInfo)
        // window?.sa('send', 'pageEnter', { start_time: new Date().getTime() })
        const anti_in = window?.AntiIn?.syncGetAllEncrypted(window?.AntiIn.Channel.PC) || null
        const risk_url = encodeURIComponent(response?.request?.responseURL)
        const armortoken = window?.__ArmorToken || window?.sessionStorage.getItem('armorToken') || window?.localStorage.getItem('armorToken') || null
        window?.sa('send', {
          activity_name: 'expose_risk_envinfo',
          activity_param: {
            origin_risk_id,
            anti_in,
            armortoken,
            risk_url,
            response: response?.data
          }
        })
        // window?.sa('send', 'pageLeave', { end_time: new Date().getTime() })
      }
    } catch (error) {
      console.log(error)
    }
    return response
  },
  error: function (error) {
    if (typeof error?.request !== 'undefined') {
      apiReport.sendReportForScHttp(error)

      // 暂时不采集 ERR_BAD_REQUEST、ERR_CANCELED、ERR_BAD_OPTION_VALUE、ERR_BAD_OPTION、ERR_DEPRECATED的错误
      tpmRecord({
        resource: error?.config?.customdata?.subUrl || 'other',
        data: [{
          key_path: 'request_error',
          values: {
            status_code: `10010${error?.request?.status}`
          }
        }]
      }, {
        immediate: true
      })

      try {
        // 判断 反爬拦截
        if (isWafIntercepted(error?.response?.headers) || isReportBlocked()) throw error

        // response status为unll undifined 空时不上报
        const errorStaus =  error?.response?.status || 0
        if(+errorStaus < 1) throw error
        const { langPath = '' } = gbCommonInfo
        const metricName = error?.config?.useBffApi ? 'bff_api_request_status_error' : 'api_request_status_error'
        const apiUrl = error?.config.customdata?.subUrl || 'other'
        SIMetric.metricCount({
          metric_name: metricName,
          tags: {
            siteuid: gbCommonInfo?.SiteUID,
            apiUrl: apiUrl.substring(langPath.length),
            errorCode: errorStaus,
            hostname: location.hostname,
            referer: document.referer
          },
          message: error?.message
        }, {
          immediate: true
        })
      } catch (error) {
        console.error(error)
      }
    }

    try {
      //反爬埋点-实时引擎
      const status = error?.response?.status
      const origin_risk_id = error?.response?.headers?.['risk-id']
      if (status == 403 && origin_risk_id) {
        const SaPageInfo = {
          page_name: 'page_risk_crawler_block',
          page_id: 6407
        }
        window?.sa('set', 'setPageData', SaPageInfo)
        // window?.sa('send', 'pageEnter', { start_time: new Date().getTime() })
        const anti_in = window?.AntiIn?.syncGetAllEncrypted(window?.AntiIn.Channel.PC) || null
        const risk_url = encodeURIComponent(error?.request?.responseURL)
        const armortoken = window?.__ArmorToken || window?.sessionStorage.getItem('armorToken') || window?.localStorage.getItem('armorToken') || null
        window?.sa('send', {
          activity_name: 'expose_risk_envinfo',
          activity_param: {
            origin_risk_id,
            anti_in,
            armortoken,
            risk_url,
            response: error?.response?.data
          }
        })
        // window?.sa('send', 'pageLeave', { end_time: new Date().getTime() })
      }
    } catch (error) {
      console.log(error)
    }
    throw error
  }
}
// ===== END =====

// ===== 算签补偿插件 =====
const rewriteSignature = {
  name: 'rewriteSignature',
  active: () => gbCommonInfo.OPEN_SIGNATURE && (gbCommonInfo.NODE_SERVER_ENV === 'gray' || gbCommonInfo.NODE_SERVER_ENV === 'production'),
  range: () => true,
  check: ({ data }) => data?.code == 836000 && data?.sub_code == 5511,
  fix: () => window._SHEIN_SYNC_TIMESTAMP_(gbCommonInfo.langPath + '/_openapi_gateway_/timestamp'),
}
// ===== 极验挑战插件 =====
const geetest = {
  name: 'geetest',
  active: () => true,
  range: ({ url }) => [
    /\/cart\/update/,
    /\/cart\/add_mall/,
    /\/api\/comment\/abcCommentImages\/query/,
    /\/api\/comment\/abcCommentInfo\/query/,
    // TODO 这块路由后面可以删掉了 ⬇️
    /\/goods_detail_nsw\/getCommentByAbc/,
    /\/goods_detail_nsw\/getCommentInfoByAbc/,
    // TODO 这块路由后面可以删掉了 ⬆️
  ].some(reg => reg.test(url)),
  check: ({ data }) => data?.code == 836100 && data?.sub_code && data?.attachment?.cookie_duration,
  fix: (r) => runFlowChallenge(r.data.sub_code, r.data.attachment.cookie_duration, r?.headers?.['risk-id'])
    .then(data => { if (data.status !== 'success') { data.status !== 'geetest_close' && SMessage({ type: 'error', message: gbCommonInfo.language.SHEIN_KEY_PC_16509 }); throw data } }),
}
// ===== 无感重放 =====
class SignatureFeedBack {
  replayQueue = {}
  plugins = []
  _seed = 9999
  constructor(plugins) {
    if (typeof window === 'undefined' || typeof gbCommonInfo === 'undefined') return

    plugins.forEach(({ name: pluginName, active, range, check, fix }) => {
      if (typeof pluginName !== 'string' || typeof active !== 'function' || typeof range !== 'function' || typeof check !== 'function' || typeof fix !== 'function') return
      if (!active()) return

      this.plugins.push({ pluginName, range, check, fix, groupId: 0 })
    })

    this.interceptor = {
      hold: this._hold.bind(this),
      replay: instance => response => this._replay(response, instance),
    }
  }

  async _hold(config) {
    if (!config.customdata) config.customdata = {}
    config.customdata.groupId = {}

    await Promise.all(this.plugins.map(async plugin => {
      if (plugin.range(config)) await plugin.holdingPromise
      // eslint-disable-next-line require-atomic-updates
      config.customdata.groupId[plugin.pluginName] = plugin.groupId
    }))
    return config
  }

  async _replay(response, schttp) {
    const responseGroupId = response.config?.customdata?.groupId
    if (!responseGroupId) return response

    // 来自重放流量
    const sourceReplayId = response.config.headers['x-gw-replay']
    if (sourceReplayId) {
      // 原流量接管
      this.replayQueue[sourceReplayId](response)
      delete this.replayQueue[sourceReplayId]
      // 终止重放请求
      throw new Error('abandon from replay')
    }

    let isOk = true
    let fixFaild = false
    await Promise.all(this.plugins.map(async plugin => {
      if (plugin.check(response)) {
        isOk = false
        const fixSuccess = await this._doFix(response, responseGroupId, plugin)
        if (!fixSuccess) fixFaild = true
      }
    }))
    // 来自正常流量或者修复失败
    if (isOk || fixFaild) return response

    // 无感重放
    const uniqueId = this._createUniqueId()
    const promise = this._saveQueue(uniqueId)
    const config = this._resetConfig(uniqueId, response.config)
    schttp(config).catch(e => console.log('replay success: ', e.message === 'abandon from replay'))

    return promise
  }

  async _doFix(response, responseGroupId, plugin) {
    /**
     * 同组且没更新过才会发起
     * 否则返回当前的 promise (若有, 来自本次或后续批次)
     * 没有则返回 null 直接重发
     */
    if (responseGroupId[plugin.pluginName] !== plugin.groupId || plugin.holdingPromise) return plugin.holdingPromise

    return plugin.holdingPromise = (async () => {
      let fixSuccess = true
      try {
        await plugin.fix(response)
      } catch (error) {
        console.error(error)
        fixSuccess = false
      }
      // 更新完签名, 后续请求分到下一批次
      plugin.groupId++
      delete plugin.holdingPromise
      return fixSuccess
    })()
  }

  _createUniqueId() {
    if (this._seed < 1000) this._seed = 9999
    return this._seed--
  }

  _saveQueue(key) {
    let resolve = null
    const promise = new Promise(res => resolve = res)
    this.replayQueue[key] = resolve
    return promise
  }

  _resetConfig(key, config) {
    config.headers['x-gw-replay'] = key
    // 从根级去除, 以免新的 common 混不进来
    delete config.headers['x-gw-auth']
    delete config.headers['x-csrf-token']
    return config
  }
}

export const signatureFeedBack = new SignatureFeedBack([
  rewriteSignature,
  geetest
]).interceptor
// ===== END =====

// ===== 全链路监控 =====
// * 依赖 apiSignatureRequest 拦截器埋设的数据
export const traceMonitor = {
  /**
   * 生成随机字符串
   * @param {Number} length 字符串长度
   * @param {String} strScope 字符采样源
   * @returns {String} 定长随机数
   */
  _getRandomString(length, strScope = '0123456789') {
    let result = ''
    for (let i = length; i > 0; --i) {
      result += strScope[Math.floor(Math.random() * strScope.length)]
    }
    return result
  },
  /**
   * 生成全链路的起始span信息
   */
  _getRootSpanInfo() {
    const { _getRandomString } = traceMonitor
    const buf = `ff${_getRandomString(14, '0123456789abcdef')}`
    const rootSpanId = buf.toString()
    return {
      traceID: rootSpanId,
      spanID: rootSpanId,
    }
  },
  /** 缓存采样配置，避免频繁读取 localStorage */
  _sampleControlConfig: null,
  /**
   * 获取采样率
   */
  _getSampleRate(reqConfig) {
    if (typeof window === 'undefined' || typeof gbCommonInfo === 'undefined') {
      return 0
    }

    // 非生产环境全部采样
    if (['localhost', 'debug', 'gray'].includes(gbCommonInfo.NODE_SERVER_ENV)) {
      return 1
    }

    /** 生产环境兜底采样率 0.001 */
    const defaultSampleRate = 0.001
    let currentSampleControlConfig = null
    if (traceMonitor._sampleControlConfig) {
      const { value, end } = traceMonitor._sampleControlConfig
      currentSampleControlConfig = value

      // 当前采样配置过期
      if (Date.now() > end) {
        traceMonitor._sampleControlConfig = null
      }
    } else {
      const cacheValue = getLocalStorage('sky_eye_sample_control_config')
      if (cacheValue) {
        currentSampleControlConfig = cacheValue
        traceMonitor._sampleControlConfig = {
          value: cacheValue,
          // 建议小于 localStorage 中设置的缓存时长，控制好双重缓存带来的总过期时长
          // 当前设置 30 mins 过期，故整份配置最大过期时长为 90 mins=(60+30)
          end: Date.now() + (1000 * 60 * 30)
        }
      }
    }

    if (currentSampleControlConfig) {
      const apiSampleControl = currentSampleControlConfig.apiMap?.[reqConfig.url]
      if (apiSampleControl) {
        return Number(apiSampleControl.prop)
      }

      return Number(currentSampleControlConfig.prop) || defaultSampleRate
    } else {
      // 未获取到采样配置，返回默认采样率并通过接口更新配置
      // 避免死循环
      if (reqConfig.url !== '/api/config/sampleControlConfig/get') {
        getSampleControlConfig()
      }
      return defaultSampleRate
    }
  },
  /**
   * 获取采样标记
   * @return sampleFlag 0: 不采样 | 1: 采样
   */
  _getSampleFlag(reqConfig) {
    const { _getSampleRate } = traceMonitor
    return Math.random() < _getSampleRate(reqConfig) ? 1 : 0
  },

  /**
   * 启动全链路跟踪根节点
   */
  startRootSpan(config) {
    if (typeof window === 'undefined' || typeof gbCommonInfo === 'undefined') {
      return config
    }
    const { _getRootSpanInfo, _getSampleFlag } = traceMonitor
    const { traceID } = _getRootSpanInfo()
    const sampleFlag = _getSampleFlag(config)
    config.headers['uber-trace-id'] = `${traceID}:${traceID}:0:${sampleFlag}`
    return config
  },

  /**
   * 获取全链路跟踪参数
   * @param {Object} reqConfig 请求配置
   * @param {Object} response 响应体
   * @returns {Object | null} 全链路跟踪参数
   * @see wiki.pageId=1091703366
   */
  _buildTraceSpanData(reqConfig, response) {
    if (
      typeof window === 'undefined' || typeof gbCommonInfo === 'undefined'
      || !reqConfig || !response
    ) {
      return null
    }

    const {
      headers: reqHeaders = {},
      customdata = {},
      method,
      url = '',
    } = reqConfig
    const uberTraceId = reqHeaders?.['uber-trace-id'] || ''
    const [traceID = '', , , sampleFlag] = uberTraceId?.split?.(':') || []
    // 采样标记检测
    if (sampleFlag !== '1') {
      return null
    }

    const startTime = customdata?.requestStartTime
    const duration = Date.now() - (startTime || 0)
    const { page_name } = window.getSaPageInfo || window.SaPageInfo || {}
    const samplerParam = `${traceMonitor._getSampleRate(reqConfig)}`
    const samplerType = samplerParam === '1' ? 'cons' : 'probabilistic'

    // TODO-tracerHack 未来删除，为识别restFUL接口而取响应头x-tracer-name字段
    const { headers: resHeaders } = response
    const tracerName = resHeaders?.['x-tracer-name']
    const operationName = tracerName || url?.split?.('?')?.[0] || '/'
    const { IS_RW, SERVER_TYPE = '', NODE_SERVER_ENV = '' } = gbCommonInfo
    const customHost = `${IS_RW ? 'romwe' : 'shein'}-PC-${SERVER_TYPE}-${NODE_SERVER_ENV}`

    return {
      traceID,
      spanID: traceID,
      parentID: '',
      startTime,
      duration,
      operationName,
      api: operationName,
      url: url || '',
      method: (method || '').toUpperCase?.() || '',
      statusCode: response.status || 504,
      ID: 'fdf304b4-9e52-56e1-b9be-159baeafb0b9',
      host: customHost,
      page: page_name,
      authName: '',
      samplerType,
      samplerParam,
      spanKind: 'client'
    }
  },

  // 请求成功拦截器
  success(response) {
    if (typeof window === 'undefined' || typeof gbCommonInfo === 'undefined') {
      return response
    }

    const { headers, config } = response || {}
    if (!headers || !config) {
      return response
    }

    // sw cache校验
    if (headers?.['x-recognize-cache']) {
      return response
    }

    const traceSpanData = traceMonitor._buildTraceSpanData(config, response)
    if (!traceSpanData) {
      return response
    }
    tpmRecord({
      data: [{
        key_path: 'trace-span',
        values: {
          span_info: JSON.stringify(traceSpanData)
        },
      }]
    }, {
      random: 1
    })
    return response
  },
  // 请求失败拦截器
  error(error) {
    if (typeof window === 'undefined' || typeof gbCommonInfo === 'undefined') {
      throw error
    }

    const { config, response } = error || {}
    if (!config) {
      throw error
    }

    const { headers } = response || {}
    // sw cache校验
    // * 容错场景，理论上不应出现
    if (headers?.['x-recognize-cache']) {
      throw error
    }

    const traceSpanData = traceMonitor._buildTraceSpanData(config, response)
    if (!traceSpanData) {
      throw error
    }
    tpmRecord({
      data: [{
        key_path: 'trace-span',
        values: {
          span_info: JSON.stringify(traceSpanData)
        },
      }]
    }, {
      random: 1
    })

    throw error
  }
}
// ===== END =====

// ===== add ipv6 info for data report =====
export function ipv6(response) {
  if (typeof window !== 'undefined') {
    const headers = response.headers
    const clientIpver = headers['Client-Ipver'] || headers['client-ipver'] || (headers?.get ? headers?.get('Client-Ipver') : '') || ''
    const clientIpvaddr = headers['Client-Ipaddr'] || headers['client-ipaddr'] || (headers?.get ? headers?.get('Client-Ipaddr') : '') || ''
    if (clientIpver) {
      window.__CLIENT_IPVER__ = clientIpver
    }
    if (clientIpvaddr) {
      window.__CLIENT_IPADDR__ = clientIpvaddr
    }
  }
  return response
}
// ===== END =====

// ===== 用于处理特殊情况的baseURL =====
// 例如：中间层聚合需求，需要请求app中间层获取数据，需要对baseURL进行改写

export function dataAggregation(config) {
  if (typeof window === 'undefined' || typeof gbCommonInfo === 'undefined') {
    return config
  }
  // const _oest = UserInfoManager.get({ key: 'oest' })
  // config.headers['x-oest'] = encryptOest(_oest)
  config.headers['x-oest'] = OestClientHandle.getEnptValue()
  // config.headers['x-oest-origin'] = UserInfoManager.get({ key: 'oest' })
  
  if (config.useBffApi) {
    const { langPath = '', WEB_VERSION } = gbCommonInfo
    config.baseURL = `${langPath}/bff-api`
    config.headers['webVersion'] = WEB_VERSION

    if (config.url?.startsWith('/ltspc/')) {
      config.baseURL = `${langPath}/ltspc/bff-api`
      config.headers['bff-source'] = 'shein;pc'
    }

    if (['debug', 'localhost', 'gray'].includes(gbCommonInfo.NODE_SERVER_ENV)) {
      const { bffEnv } = gbCommonInfo
      config.headers['bff-env'] = bffEnv
      config.headers['sheindebug'] = '676'
    }
  }

  if(config.useBffApi || config.__fromBSLibs__) {
    config.headers['x-ad-flag'] = gbCommonInfo.xAdFlag
  }

  return config
}
// ===== END =====

// ===== 用于处理端智能夹带数据 =====
export function aiDataRequestInterceptor(config) {
  if (typeof window === 'undefined' || typeof gbCommonInfo === 'undefined') {
    return config
  }
  const featureKey = config.featureKey // 用于标识是否需要端智能夹带数据
  if (!featureKey) {
    return config
  }

  if (!['post', 'POST'].includes(config.method)) {
    // 非post请求，不支持端智能夹带数据
    return config
  }
  const aiData = getUserActionTrackerData(featureKey)
  if (!aiData) return config
  
  config.headers['hdata-c'] = 'ai'

  // 判断请求头为x-www-form-urlencoded的请求，将数据放在数据中
  if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
    if (!config.data) {
      config.data = {}
    }
    if (config.data && typeof config.data === 'object') {
      config.data.data_c = {
        ai: aiData
      }
    }  else {
      // 判断是否有data参数并且data参数是字符串
      if (config.data && typeof config.data === 'string') {
        config.data = parseQueryString(config.data)
        config.data.data_c = JSON.stringify({
          ai: aiData
        })
        config.data = stringifyQueryString({ queryObj: config.data })
      }
    }
    return config
  }

  if (config.data) {
    config.data = { ...(config.data || {}), data_c: { ai: aiData } }
  } else {
    config.params = { ...(config.params || {}), data_c: { ai: aiData } }
  }
  return config
}

// ===== 同盾4.x版本加签 =====
export function tongdunSignature(config) {
  if (typeof window === 'undefined' || typeof gbCommonInfo === 'undefined') return config
  const url = config.url || ''
  if (!(gbCommonInfo?.FM_UPGRADE === 'on')) return config
  if (!gbCommonInfo?.FM_SIGN_URLS.some(path => url.includes(path))) return config
  let sigUrl = axios.getUri(config)
  try {
    if (window?._TDBotD?.sign) {
      sigUrl = sigUrl.split('?')?.[0] + '?' + sigUrl.split('?')?.[1]?.replace(/'/g, '%27')
      const fingalsign = window._TDBotD.sign({ path: sigUrl })
      if (fingalsign.code == 0) {
        config.headers['tdSign'] = fingalsign.sign
      }
      SIMetric?.metricCount({
        metric_name: 'td_compute_log',
        tags: {
          status: fingalsign.code == 0 ? 'sign_success' : 'sign_error',
          signCode: fingalsign.code,
          message: JSON.stringify(fingalsign),
          apolloUrl: url,
          sigUrl: sigUrl
        },
        message: 'tongdunSignature'
      }, {
        immediate: true
      })
    }
    SIMetric?.metricCount({
      metric_name: 'td_compute_log',
      tags: {
        status: 'sign_all',
        signCode: 99999,
        message: JSON.stringify({ message: 'sign_all', code: 99999 }),
        apolloUrl: url,
        sigUrl: sigUrl
      },
      message: 'tongdunSignature'
    }, {
      immediate: true
    })
  } catch (err) {
    SIMetric?.metricCount({
      metric_name: 'td_runtime_error',
      tags: {
        stack: err?.stack,
        message: err?.message,
        apolloUrl: url,
        sigUrl: sigUrl
      },
      message: 'td_runtime_error'
    }, {
      immediate: true
    })
  }
  return config
}
// ===== END =====

// ===== 更新xAdFlag =====
export function updateXAdFlag(response) {
  if (typeof window === 'undefined' || typeof gbCommonInfo === 'undefined') {
    return response
  }

  if(response.headers?.['update-x-ad-flag']){
    gbCommonInfo.xAdFlag = response.headers['update-x-ad-flag']
  }

  return response
}
